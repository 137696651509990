export default [
	// 流程引擎编辑新增
	{
		path: 'flowEdit',
		name: 'flowEdit',
		component: () => import('@/views/organizationManage/flowManage/flowEdit.vue'),
	},
	// 自定义流程引擎编辑新增
	{
		path: 'selfDefinedFlowEdit',
		name: 'selfDefinedFlowEdit',
		component: () => import('@/views/organizationManage/flowManage/selfDefinedFlowEdit.vue'),
	},
	// 流程引擎配置
	{
		path: 'flowConfig',
		name: 'flowConfig',
		component: () => import('@/views/organizationManage/flowManage/flowConfig.vue'),
	},
	// 业务类型详情
	{
		path: 'flowTypeDetail',
		name: 'flowTypeDetail',
		component: () => import('@/views/organizationManage/flowManage/flowType/detail.vue'),
	},
	// 编号类型编辑新增
	{
		path: 'codeNumberAddorEdit',
		name: 'codeNumberAddorEdit',
		component: () => import('@/views/organizationManage/codeNumber/codeNumberAddorEdit.vue'),
	},
	// 菜单编辑新增
	{
		path: 'menuEdit',
		name: 'menuEdit',
		component: () => import('@/views/organizationManage/menuManage/components/menuEdit.vue'),
	},
	// 添加待办模板
	{
		path: 'backlogTemplateAdd',
		name: 'backlogTemplateAdd',
		component: () =>
			import(
				'@/views/organizationManage/backlogManage/backlogTemplate/components/backlogTemplateAdd.vue'
			),
	},
	// 待办模板详情
	{
		path: 'backlogTemplateDetail',
		name: 'backlogTemplateDetail',
		component: () =>
			import('@/views/organizationManage/backlogManage/backlogTemplate/detail.vue'),
	},
]
